* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  text-decoration: none !important;
  /* font-family: system-ui !important; */
  font-family: "Inter", sans-serif;
}

@font-face {
  font-family: "ClashDisplay";
  src: url("../public/fonts/ClashDisplay-Regular.otf");
}

@font-face {
  font-family: "Cyrene";
  src: url("../public/fonts/Cyrene\ Regular.otf");
}

.land-font * {
  font-family: "ClashDisplay", sans-serif !important;
  letter-spacing: 0.5px;
}
.logo {
  font-family: "Cyrene", sans-serif !important;
}
.google-logo {
  height: 18px;
  width: 18px;
}

.cursor-pointer {
  cursor: pointer;
}
.flex-wrap {
  flex-wrap: wrap;
}
.d-flex {
  display: flex;
}
.flex-column {
  flex-direction: column;
}
.flex-row {
  flex-direction: row;
}
.items-center {
  align-items: center;
}
.justify-start {
  justify-content: flex-start;
}
.justify-end {
  justify-content: flex-end;
}
.justify-center {
  justify-content: center;
}
.justify-between {
  justify-content: space-between;
}
.justify-evenly {
  justify-content: space-evenly;
}
.p-0 {
  padding: 0;
}
.p-1 {
  padding: 0.125rem;
}
.p-2 {
  padding: 0.25rem;
}
.p-3 {
  padding: 0.375rem;
}
.p-4 {
  padding: 0.5rem;
}
.p-5 {
  padding: 0.625rem;
}
.p-6 {
  padding: 0.75rem;
}
.p-7 {
  padding: 0.875rem;
}
.p-8 {
  padding: 1rem;
}
.p-9 {
  padding: 1.125rem;
}
.p-10 {
  padding: 1.5rem;
}

.py-0 {
  padding: 0rem 0rem;
}
.py-1 {
  padding: 0.125rem 0rem;
}
.py-2 {
  padding: 0.25rem 0rem;
}
.py-3 {
  padding: 0.375rem 0rem;
}
.py-4 {
  padding: 0.5rem 0rem;
}
.py-5 {
  padding: 0625rem 0rem;
}
.py-6 {
  padding: 0.75rem 0rem;
}
.py-7 {
  padding: 0.875rem 0rem;
}
.py-8 {
  padding: 1rem 0rem;
}
.py-9 {
  padding: 1.125rem 0rem;
}
.py-10 {
  padding: 1.5rem 0rem;
}

.px-0 {
  padding: 0rem 0rem;
}
.px-1 {
  padding: 0rem 0.125rem;
}
.px-2 {
  padding: 0rem 0.25rem;
}
.px-3 {
  padding: 0rem 0.375rem;
}
.px-4 {
  padding: 0rem 0.5rem;
}
.px-5 {
  padding: 0rem 0.625rem;
}
.px-6 {
  padding: 0rem 0.75rem;
}
.px-7 {
  padding: 0rem 0.875rem;
}
.px-8 {
  padding: 0rem 1rem;
}
.px-9 {
  padding: 0rem 1.125rem;
}
.px-10 {
  padding: 0rem 1.5rem;
}

.pr-0 {
  padding-right: 0;
}
.pr-1 {
  padding-right: 0.125rem;
}
.pr-2 {
  padding-right: 0.25rem;
}
.pr-3 {
  padding-right: 0.375rem;
}
.pr-4 {
  padding-right: 0.5rem;
}
.pr-5 {
  padding-right: 0625rem;
}
.pr-6 {
  padding-right: 0.75rem;
}
.pr-7 {
  padding-right: 0.875rem;
}
.pr-8 {
  padding-right: 1rem;
}
.pr-9 {
  padding-right: 1.125rem;
}
.pr-10 {
  padding-right: 1.5rem;
}

.pl-0 {
  padding-left: 0;
}
.pl-1 {
  padding-left: 0.125rem;
}
.pl-2 {
  padding-left: 0.25rem;
}
.pl-3 {
  padding-left: 0.375rem;
}
.pl-4 {
  padding-left: 0.5rem;
}
.pl-5 {
  padding-left: 0625rem;
}
.pl-6 {
  padding-left: 0.75rem;
}
.pl-7 {
  padding-left: 0.875rem;
}
.pl-8 {
  padding-left: 1rem;
}
.pl-9 {
  padding-left: 1.125rem;
}
.pl-10 {
  padding-left: 1.5rem;
}

.pt-0 {
  padding-top: 0;
}
.pt-1 {
  padding-top: 0.125rem;
}
.pt-2 {
  padding-top: 0.25rem;
}
.pt-3 {
  padding-top: 0.375rem;
}
.pt-4 {
  padding-top: 0.5rem;
}
.pt-5 {
  padding-top: 0.625rem;
}
.pt-6 {
  padding-top: 0.75rem;
}
.pt-7 {
  padding-top: 0.875rem;
}
.pt-8 {
  padding-top: 1rem;
}
.pt-9 {
  padding-top: 1.125rem;
}
.pt-10 {
  padding-top: 1.5rem;
}

.pb-0 {
  padding-bottom: 0;
}
.pb-1 {
  padding-bottom: 0.125rem;
}
.pb-2 {
  padding-bottom: 0.25rem;
}
.pb-3 {
  padding-bottom: 0.375rem;
}
.pb-4 {
  padding-bottom: 0.5rem;
}
.pb-5 {
  padding-bottom: 0625rem;
}
.pb-6 {
  padding-bottom: 0.75rem;
}
.pb-7 {
  padding-bottom: 0.875rem;
}
.pb-8 {
  padding-bottom: 1rem;
}
.pb-9 {
  padding-bottom: 1.125rem;
}
.pb-10 {
  padding-bottom: 1.5rem;
}

.m-0 {
  margin: 0;
}
.m-1 {
  margin: 0.125rem;
}
.m-2 {
  margin: 0.25rem;
}
.m-3 {
  margin: 0.375rem;
}
.m-4 {
  margin: 0.5rem;
}
.m-5 {
  margin: 0625rem;
}
.m-6 {
  margin: 0.75rem;
}
.m-7 {
  margin: 0.875rem;
}
.m-8 {
  margin: 1rem;
}
.m-9 {
  margin: 1.125rem;
}
.m-10 {
  margin: 1.5rem;
}

.mx-0 {
  margin: 0rem 0rem;
}
.mx-1 {
  margin: 0rem 0.125rem;
}
.mx-2 {
  margin: 0rem 0.25rem;
}
.mx-3 {
  margin: 0rem 0.375rem;
}
.mx-4 {
  margin: 0rem 0.5rem;
}
.mx-5 {
  margin: 0rem 0625rem;
}
.mx-6 {
  margin: 0rem 0.75rem;
}
.mx-7 {
  margin: 0rem 0.875rem;
}
.mx-8 {
  margin: 0rem 1rem;
}
.mx-9 {
  margin: 0rem 1.125rem;
}
.mx-10 {
  margin: 0rem 1.5rem;
}

.h-100 {
  height: 100%;
}

.min-h-100 {
  min-height: 100vh;
}

.my-auto {
  margin: auto 0rem;
}
.my-0 {
  margin: 0rem 0rem;
}
.my-1 {
  margin: 0.125rem 0rem;
}
.my-2 {
  margin: 0.25rem 0rem;
}
.my-3 {
  margin: 0.375rem 0rem;
}
.my-4 {
  margin: 0.5rem 0rem;
}
.my-5 {
  margin: 0625rem 0rem;
}
.my-6 {
  margin: 0.75rem 0rem;
}
.my-7 {
  margin: 0.875rem 0rem;
}
.my-8 {
  margin: 1rem 0rem;
}
.my-9 {
  margin: 1.125rem 0rem;
}
.my-10 {
  margin: 1.5rem 0rem;
}

.mr-0 {
  margin-right: 0;
}
.mr-1 {
  margin-right: 0.125rem;
}
.mr-2 {
  margin-right: 0.25rem;
}
.mr-3 {
  margin-right: 0.375rem;
}
.mr-4 {
  margin-right: 0.5rem;
}
.mr-5 {
  margin-right: 0625rem;
}
.mr-6 {
  margin-right: 0.75rem;
}
.mr-7 {
  margin-right: 0.875rem;
}
.mr-8 {
  margin-right: 1rem;
}
.mr-9 {
  margin-right: 1.125rem;
}
.mr-10 {
  margin-right: 1.5rem;
}

.ml-0 {
  margin-left: 0;
}
.ml-1 {
  margin-left: 0.125rem;
}
.ml-2 {
  margin-left: 0.25rem;
}
.ml-3 {
  margin-left: 0.375rem;
}
.ml-4 {
  margin-left: 0.5rem;
}
.ml-5 {
  margin-left: 0625rem;
}
.ml-6 {
  margin-left: 0.75rem;
}
.ml-7 {
  margin-left: 0.875rem;
}
.ml-8 {
  margin-left: 1rem;
}
.ml-9 {
  margin-left: 1.125rem;
}
.ml-10 {
  margin-left: 1.5rem;
}

.mt-0 {
  margin-top: 0;
}
.mt-1 {
  margin-top: 0.125rem;
}
.mt-2 {
  margin-top: 0.25rem;
}
.mt-3 {
  margin-top: 0.375rem;
}
.mt-4 {
  margin-top: 0.5rem;
}
.mt-5 {
  margin-top: 0625rem;
}
.mt-6 {
  margin-top: 0.75rem;
}
.mt-7 {
  margin-top: 0.875rem;
}
.mt-8 {
  margin-top: 1rem;
}
.mt-9 {
  margin-top: 1.125rem;
}
.mt-10 {
  margin-top: 1.5rem;
}

.mb-0 {
  margin-bottom: 0;
}
.mb-1 {
  margin-bottom: 0.125rem;
}
.mb-2 {
  margin-bottom: 0.25rem;
}
.mb-3 {
  margin-bottom: 0.375rem;
}
.mb-4 {
  margin-bottom: 0.5rem;
}
.mb-5 {
  margin-bottom: 0625rem;
}
.mb-6 {
  margin-bottom: 0.75rem;
}
.mb-7 {
  margin-bottom: 0.875rem;
}
.mb-8 {
  margin-bottom: 1rem;
}
.mb-9 {
  margin-bottom: 1.125rem;
}
.mb-10 {
  margin-bottom: 1.5rem;
}

.dark-bg-00 {
  background-color: #1b1a25;
}

.dark-bg-0 {
  background-color: #ee4b6a;
}

.dark-bg-1 {
  background-color: #262736;
}

.dark-bg-2 {
  background-color: #333345;
}

.dark-bg-3 {
  background-color: #4a4a64;
}

.dark-bg-4 {
  background-color: #4a4a61;
}

.dark-bg-5 {
  background-color: #2e3042;
}

.dark-bg-6 {
  background-color: #1f212d;
}

.dark-bg-7 {
  background-color: #1c1b26;
}

.dark-bg-8 {
  background-color: #171821;
}
.dark-bg-9 {
  background-color: #282939;
}

.dark-bg-10 {
  background-color: #1b1c29;
}

.dark-bg-11 {
  background-color: #20202d;
}

.dark-bg-12 {
  background-color: #61616f;
}

.light-bg-00 {
  background-color: #1b1a25;
}

.light-bg-0 {
  background-color: #ee4b6a;
}

.light-bg-1 {
  background-color: #ffffff;
}

.light-bg-2 {
  background-color: #f1f1f4;
}
.light-bg-22 {
  background-color: #f1f1f4;
}

.light-bg-3 {
  background-color: #f8f8f8;
}

.light-bg-4 {
  background-color: #4a4a61;
}

.light-bg-5 {
  background-color: #ffffff;
}

.light-bg-6 {
  background-color: #2e3042;
}

.light-bg-7 {
  background-color: #1c1b26;
}

.light-bg-8 {
  background-color: #171821;
}

.light-bg-9 {
  background-color: #fff;
}

.light-bg-10 {
  background-color: #1b1c29;
}

.light-bg-11 {
  background-color: #20202d;
}

.light-bg-12 {
  background-color: #61616f;
}

.dark-00 {
  color: #ee4b6a;
}

.dark-0 {
  color: #ffffff;
}

.dark-1 {
  color: #eeeeee;
}

.dark-2 {
  color: #77788c;
}

.dark-3 {
  color: #c6c6c6;
}

.dark-4 {
  color: #bbbddb;
}

.dark-5 {
  color: #e2e2e2;
}
.dark-6 {
  color: #a9a9a9;
}

.dark-7 {
  color: #afafbb;
}

.dark-8 {
  color: #262736;
}

.light-00 {
  color: #ee4b6a;
}

.light-0 {
  color: #090f31;
}

.light-1 {
  color: #eeeeee;
}

.light-2 {
  color: #878896;
}

.light-3 {
  color: #c6c6c6;
}

.light-4 {
  color: #090f31;
}

.light-5 {
  color: #e2e2e2;
}

.light-6 {
  color: #a9a9a9;
}

.light-7 {
  color: #afafbb;
}

.bg-1 {
  background-color: #d9d9d9;
}
.bg-2 {
  background-color: #818181;
}
.bg-3 {
  background-color: #b4b4b4;
}
.bg-4 {
  background-color: #aeaeae;
}
.bg-5 {
  background-color: #b9b7b7;
}
.bg-6 {
  background-color: #979292;
}
.bg-7 {
  background-color: #f5f5f5;
}

.dark-label-border {
  border: 3px solid #262736;
}

.light-label-border {
  border: 3px solid #ffffff;
}

.orange-1 {
  color: #f57b51;
}

.br-1 {
  border-radius: 5px;
}

a {
  color: inherit;
}
.inputFieldDark {
  color: #eeeeee !important;
}
.inputFieldLight {
  color: #090f31 !important;
}

.inputFieldDark:-webkit-autofill {
  box-shadow: inherit;
  -webkit-box-shadow: 0 0 0 30px #333345 inset !important;
  -webkit-text-fill-color: #eeeeee !important;
}
.inputFieldLight:-webkit-autofill {
  box-shadow: inherit;
  -webkit-box-shadow: 0 0 0 30px #ffffff inset !important;
  -webkit-text-fill-color: #090f31 !important;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
html {
  scroll-behavior: smooth;
}

* {
  scrollbar-width: 4px;
  scrollbar-color: #5f5f81 #4a4a64;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 4px;
}

*::-webkit-scrollbar-track {
  background: #000000;

  /* background-clip: content-box; */
}
::-webkit-scrollbar-thumb:vertical {
  height: 100px;
}

*::-webkit-scrollbar-thumb {
  background-color: #d63447;
  /* border-radius: 20px; */
  width: 4px;
  border: 2px solid #d63447;
}

.thin-scrollbar {
  scrollbar-width: 2px !important;
}

/* Works on Chrome, Edge, and Safari */
.thin-scrollbar::-webkit-scrollbar {
  width: 2px !important;
}

.thin-scrollbar::-webkit-scrollbar-thumb:vertical {
  height: 100px !important;
}

.thin-scrollbar::-webkit-scrollbar-thumb {
  /* border-radius: 20px; */
  width: 2px !important;
  border: 2px solid #d63447 !important;
}

.slide-top {
  transition: transform 1000ms;
  transform: translateY(-100px);
}
.slide-down {
  transition: transform 450ms;
  transform: translateY(0px);
}

.hide-scrollbar {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
.hide-scrollbar::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

/* .parent-item {
  height: 400px;
  scroll-padding-block: 0px;
  overflow-x: hidden;
  overflow-y: auto;
  white-space: nowrap;
  scroll-snap-type: y mandatory;
}
.child-item {
  height: 400px;

  scroll-snap-align: start none;
} */
/* .parent-item1 {
  height: 600px;
  scroll-padding-block: 0px;
  overflow-x: hidden;
  overflow-y: auto;
  scroll-snap-type: y mandatory;
}
.child-item1 {
  height: 600px;

  scroll-snap-align: start none;
} */

.billboard__container {
  /* height: 100vh; */
}

.billboard {
  margin: 0 auto;
  left: 10%;
  top: 50%;
  transform: translate3d(-10%, -50%, 0);
  transition: transform 1s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.billboard__image {
  height: 100vh;
  width: 100%;
  background-image: url("https://images.unsplash.com/photo-1523726491678-bf852e717f6a?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80");
  background-repeat: no-repeat;
  background-size: cover;
}

.section-border {
  will-change: transform, opacity;
  transition: transform 1s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.billboard div {
  max-width: 90%;
}

.billboard img {
  width: 100%;
  height: 900px;
  object-fit: cover;
}

@media only screen and (max-width: 760px) {
  .billboard {
    left: 10%;
    top: 50%;
    transform: translate3d(0%, -50%, 0);
  }
}

:root {
  --card-height: 40vw;
  --card-margin: 4vw;
  --card-top-offset: 1em;
  --numcards: 4;
  --outline-width: 0px;
}

#cards {
  padding-bottom: calc(
    var(--numcards) * var(--card-top-offset)
  ); /* Make place at bottom, as items will slide to that position*/
  margin-bottom: var(
    --card-margin
  ); /* Don't include the --card-margin in padding, as that will affect the scroll-timeline*/
}

#card_1 {
  --index: 1;
}

#card_2 {
  --index: 2;
}

#card_3 {
  --index: 3;
}

#card_4 {
  --index: 4;
}

.card {
  position: sticky;
  top: 0;
  padding-top: calc(var(--index) * var(--card-top-offset));
}

@supports (animation-timeline: works) {
  @scroll-timeline cards-element-scrolls-in-body {
    source: selector(body);
    scroll-offsets:
			/* Start when the start edge touches the top of the scrollport */ selector(
          #cards
        )
        start 1,
      /* End when the start edge touches the start of the scrollport */
        selector(#cards) start 0;
    start: selector(#cards) start 1; /* Start when the start edge touches the top of the scrollport */
    end: selector(#cards) start 0; /* End when the start edge touches the start of the scrollport */
    time-range: 4s;
  }

  .card {
    --index0: calc(var(--index) - 1); /* 0-based index */
    --reverse-index: calc(var(--numcards) - var(--index0)); /* reverse index */
    --reverse-index0: calc(
      var(--reverse-index) - 1
    ); /* 0-based reverse index */
  }

  .card__content {
    transform-origin: 50% 0%;
    will-change: transform;

    --duration: calc(var(--reverse-index0) * 1s);
    --delay: calc(var(--index0) * 1s);

    animation: var(--duration) linear scale var(--delay) forwards;
    animation-timeline: cards-element-scrolls-in-body;
  }

  @keyframes scale {
    to {
      transform: scale(calc(1.1 - calc(0.1 * var(--reverse-index))));
    }
  }
}
.wmde-markdown {
  background: none !important;
}

.slide-in-right {
  -webkit-animation: slide-in-right 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-in-right 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(1000px);
    transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(1000px);
    transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
.slide-in-right1 {
  -webkit-animation: slide-in-right 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-in-right 1.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes slide-in-right1 {
  0% {
    -webkit-transform: translateX(1000px);
    transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-right1 {
  0% {
    -webkit-transform: translateX(1000px);
    transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

.slide-in-right2 {
  -webkit-animation: slide-in-right 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-in-right 1.8s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes slide-in-right2 {
  0% {
    -webkit-transform: translateX(1000px);
    transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-right2 {
  0% {
    -webkit-transform: translateX(1000px);
    transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

.slide-in-right3 {
  -webkit-animation: slide-in-right 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-in-right 2.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes slide-in-right3 {
  0% {
    -webkit-transform: translateX(1000px);
    transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-right3 {
  0% {
    -webkit-transform: translateX(1000px);
    transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

.ql-toolbar.ql-snow + .ql-container.ql-snow {
  border: none !important;
}
.ql-toolbar.ql-snow {
  border: none !important;
}

.note_card:hover {
  background: "#fff";
}

.quill {
  height: 100% !important;
}

.quill > .ql-container > .ql-editor.ql-blank::before {
  color: gray !important;
  font-style: normal !important;
}

.pac-item {
  padding: 2px;
  font-size: 14px;
  background-color: #333345 !important;
  color: #eeeeee !important;
  cursor: pointer;
  border-top: 0.1px solid #adadad !important;
}
.pac-icon {
  display: none;
}
.pac-item:hover {
  background-color: #4a4a61 !important;
}
.pac-container:after {
  /* Disclaimer: not needed to show 'powered by Google' if also a Google Map is shown */

  background-image: none !important;
  height: 0px;
}
.pac-container {
  border: "none" !important;
}
.mobile-menu {
  width: 100%;
  height: calc(100vh - 60px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.rotate-text {
  transform: rotate(-90deg);
}
.faq-answer {
  transition: all 5s ease;
  -webkit-transition: all 5s ease;
}

let-spa {
  letter-spacing: 10px !important;
}

.marquee1 {
  .marquee {
    min-width: fit-content;
  }
}

.marque-text {
  margin: 0 1.2rem !important;
}

.skills,
.langs {
  /* text-transform: uppercase; */
  font-size: 4vw;
  display: inline-block;
  white-space: nowrap;
  /* animation: floatText 25s infinite linear; */
  animation: 5.856s linear 0s infinite normal none running marquee;
  /* padding-left: 100%; */
  color: #ada97d;
  /* margin-top: 12rem; */
  overflow-x: auto;
}
@keyframes floatText {
  to {
    transform: translateX(-100%);
  }
}
/* .skills:hover,
.langs:hover {
  animation: floatText 25s infinite linear;
} */

.img-tr {
  transition-timing-function: linear;
  -webkit-transition: opacity 5s linear;
  -moz-transition: opacity 5s linear;
  -o-transition: opacity 5s linear;
  transition: opacity 5s linear;
}

.overlay::before,
.overlay::after {
  background: none !important;
}

.img-fit {
  object-position: unset;
}

@media only screen and (max-width: 600px) {
  .img-fit {
    object-position: center;
    objectfit: "cover";
  }
}

/* @media screen and (max-width: 768px) {
  [data-aos] {
    pointer-events: auto !important;
  }

  html:not(.no-js) [data-aos^="fade"][data-aos^="fade"] {
    opacity: 1 !important;
  }

  html:not(.no-js) [data-aos="fade-up"] {
    transform: none !important;
  }
  html:not(.no-js) [data-aos="fade-right"] {
    transform: none !important;
  }
} */

/* .animate-card {
  transition: all 500ms ease-in-out;
}
.animate-card:hover {
  box-shadow: 0px 12px 30px 0px rgba(0, 0, 0, 0.2);
  transition: all 800ms cubic-bezier(0.19, 1, 0.22, 1);
} */

.input-nopadding {
  padding: 0rem !important;
}

u {
  text-decoration: underline !important;
}

.note-preview {
  font-size: 12px !important;
}
.note-preview h1 {
  font-size: 12px !important;
  font-weight: normal !important;
}
.note-preview h2 {
  font-size: 12px !important;
  font-weight: normal !important;
}
.note-preview h3 {
  font-size: 12px !important;
  font-weight: normal !important;
}
.note-preview h4 {
  font-size: 12px !important;
  font-weight: normal !important;
}
.note-preview u {
  text-decoration: none !important;
}

.note-preview strong {
  font-weight: normal !important;
}
img {
  object-fit: cover !important;
  object-position: center !important;
}

.MuiFormHelperText-root.Mui-error {
  margin-left: 0rem !important;
}
